import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';

import Layout, { Content } from './components/Layout';

import {
  Content as ContentV2,
  Layout as LayoutV2,
} from './components/LayoutV2';

import HeroHeader from './components/heroheader';
import { HeroHeader as HeroHeaderV2 } from './components/Programme/HeroHeader';

import PageContent from './components/Page';
import ContentNavigation from './components/Navigation/Content';
import { CookieDeclaration } from './components/theme/3rdParty/CookieDeclaration';

export const query = graphql`
  query PageQuery($id: Int!) {
    strapiPages(strapiId: { eq: $id }) {
      Display_Title
      Headline
      Slug
      Key_Visual {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
        }
      }
      CTA {
        Text
        URL
        Button
      }
      Introduction
      Metadata {
        id
        Title
        Description
      }
      Content {
        id
        Title
        Subtitle
        Content
        ButtonText
        CTA_Text
        CTA_URL
        Colour
        IconLayout
        ImageLayout
        Introduction
        YouTube_URL
        URL
        Decoration
        ContentLayout
        ColorVariant
        Restriction
        Button {
          Text
          URL
        }
        Buttons {
          Text
          URL
        }
        SideBySideDisplay
        Travel_With_Confidence_Title
        Layout
        ReviewsTitle
        ReviewsSubtitle
        Box_Item {
          Box_Title
          Box_Link
          BoxImage {
            publicURL
          }
        }
        Redirect_Title
        Redirect_Content
        Redirect_Item {
          Redirect_Title
          Redirect_Content
          Redirect_Link
          RedirectImage {
            publicURL
          }
        }
        Icon {
          publicURL
        }
        ContentDisplayImage {
          publicURL
        }
        SpotlightTitle
        TitleContent
        TitleNote
        Description
        BackgroundType
        Brush {
          Stroke_Type
        }
        Key_Visual {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        Galleries {
          Title
          id
        }
        JobRoles {
          Title
        }
        Questions {
          Answer
          Question
          id
        }
        Steps {
          Title
          Subtitle
          Content
          id
        }
        Locations {
          Camp
          Location
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            Text
            URL
          }
        }
        Icons {
          Content
          Key_Visual {
            publicURL
          }
        }
        Quote_Tabs {
          Tab_Title
          Quotes {
            id
            Content
            Person
            Experience
          }
        }
        Blogs {
          id
          Title
          Intro
          Content
          Category
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        Quotes {
          id
          Content
          Person
          Experience
        }
        TextQuotes {
          id
          Content
          Person
          Experience
        }
        Grid_Items {
          Question
          Answer
        }
        Item1 {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            id
            Text
            URL
          }
        }
        Item2 {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            id
            Text
            URL
          }
        }
        Product_One {
          Title
          Subtitle
          Introduction
          Details
          First_Payment {
            Product_Title
            Product {
              id
            }
          }
          Desposit {
            Product_Title
            Product {
              id
            }
          }
          Second_Payment {
            Product_Title
            Product {
              id
            }
          }
          Final_Text
          Include_Total
          Button {
            Text
            URL
          }
        }
        Product_Two {
          Title
          Subtitle
          Introduction
          Details
          First_Payment {
            Product_Title
            Product {
              id
            }
          }
          Desposit {
            Product_Title
            Product {
              id
            }
          }
          Second_Payment {
            Product_Title
            Product {
              id
            }
          }
          Final_Text
          Include_Total
          Button {
            Text
            URL
          }
        }
        Items {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            Text
            URL
          }
        }
      }
    }
  }
`;

interface Props {
  data: any;
  pageContext: any;
  location: any;
}

const Page: FunctionComponent<Props> = ({ data, pageContext, location }) => {
  const page = data.strapiPages;
  const keyVisualUrl = page.Key_Visual ? page.Key_Visual.publicURL : '';
  const navigation = pageContext.navigation;
  const navigationLinks = [].concat(navigation);
  const metadata = page.Metadata || { Title: false };

  const v2Pages = [
    '/contact',
    '/gap-years',
    '/about-bunac',
    '/about-bunac/why-bunac',
    '/about-bunac/booking-confidence/booking-promise',
    '/for-organisations/partner-with-us',
    '/cookie-policy',
    '/privacy-policy',
    '/terms-and-conditions',
    '/working-holidays/working-holidays-travel-insurance',
    '/information-for-parents',
    '/careers',
    '/about-bunac/travel-advice',
    '/united-states',
    '/australia',
    '/canada',
    '/new-zealand',
    '/uk',
    '/blog',
    '/blog/usa',
    '/blog/summer-camp',
    '/blog/canada',
    '/blog/uk',
    '/blog/australia',
    '/blog/new-zealand',
    '/blog/japan',
  ];

  function getContentByLayout() {
    var allContent;

    allContent = v2Pages.includes(page.Slug) ? (
      <LayoutV2
        className="layout-v2"
        title={metadata.Title || page.Headline || page.Display_Title}
        desc={metadata.Description || ''}
        noIndex={metadata.noIndex}
      >
        {keyVisualUrl && (
          <HeroHeaderV2
            title={page.Display_Title}
            subtitle={page.Headline}
            text={page.Introduction}
            cta={page.CTA ? page.CTA.Text : undefined}
            url={page.CTA ? page.CTA.URL : undefined}
            image={page.Key_Visual}
            includeCountrySelector={page.Display_Title.indexOf('Visa') >= 0}
          />
        )}
        <ContentV2 fullWidth>
          {page.Slug === '/cookie-policy' ? (
            <CookieDeclaration />
          ) : (
            page &&
            page.Content &&
            page.Content.map((content: any, index: number) => (
              <PageContent key={index} content={content} layoutVersion={'V2'} />
            ))
          )}
        </ContentV2>
      </LayoutV2>
    ) : (
      <Layout
        title={metadata.Title || page.Headline || page.Display_Title}
        description={metadata.Description || ''}
        noIndex={metadata.noIndex}
      >
        {keyVisualUrl && (
          <HeroHeader
            cta={page.CTA}
            title={page.Headline || page.Display_Title}
            backgroundUrl={keyVisualUrl}
          />
        )}
        <ContentNavigation
          links={navigationLinks}
          currentPathName={location.pathname}
          theme=""
        />
        <Content>
          {page.Content &&
            page.Content.map((content: any) => (
              <div key={content.id}>
                <PageContent content={content} />
              </div>
            ))}
        </Content>
      </Layout>
    );

    return allContent;
  }

  return getContentByLayout();
};

export default Page;
