import React, { FunctionComponent } from 'react';

interface BlogHeaderProps {
  title?: string;
  subtitle?: string;
}

const BlogHeader: FunctionComponent<BlogHeaderProps> = ({
  title = 'Blog',
  subtitle,
}) => (
  <div className="px-6 py-16 max-w-screen-lg mx-auto bg-blue rounded-3xl shadow-md flex flex-col items-center space-x-4">
    <h1 className="flex-none text-5xl font-worksans font-light text-white mb-3">
      {title}
    </h1>
    {subtitle && (
      <h2 className="flex-none text-xl font-worksans font-semibold uppercase text-navy">
        {subtitle}
      </h2>
    )}
  </div>
);

export { BlogHeader };
