import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  DEFAULT_CURRENCY,
  currency as getCurrency,
  Currencies,
} from '@bunac/components/config/currency';
import createPersistedState from 'use-persisted-state';

const CurrenctContainer = styled.div`
  margin-top: 24px;
`;

const Container = styled.div`
  position: relative;
  border: 1px solid white;
  border-radius: 1.5rem;
  width: 140px;
  z-index: 1;
  color: #fff;
  background: #5fbec2;
`;

const CurrentCurrency = styled.button`
  position: relative;
  display: block;
  width: inherit;
  border: 0 none;
  outline: none;
  background: no-repeat 9px center;
  background-size: 20px 20px;
  padding: 5px 0 5px 36px;
  text-align: left;
  font-size: 16px;
  z-index: 100;

  &.AUD {
    background-image: url('/images/currencies/aud.svg');
  }
  &.EUR {
    background-image: url('/images/currencies/euro.svg');
  }
  &.GBP {
    background-image: url('/images/currencies/gbp.svg');
  }
  &.NZD {
    background-image: url('/images/currencies/nzd.svg');
  }
  &.USD {
    background-image: url('/images/currencies/usa.svg');
  }
`;

const CurrencyMenuOpener = styled.div`
  position: absolute;
  top: 0;
  right: 2px;
  width: 32px;
  height: 32px;
  border-left: 1px solid #fff;
  background: url('/images/arrow-down-white.svg') no-repeat center center;

  &.open {
    background-image: url('/images/arrow-up-white.svg');
  }
`;

const CurrencyOptions = styled.div`
  position: absolute;
  top: 20px;
  left: -1px;
  background: #5fbec2;
  border-radius: 0 0 25px 25px;
  padding-top: 18px;
  z-index: 1;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
`;

const CurrencyDivider = styled.div`
  border-top: 1px solid white;
`;

const CurrencyOptionItem = styled.button`
  display: block;
  width: 138px;
  border: 0 none;
  outline: none;
  background: no-repeat 90% 7px;
  background-size: 20px 20px;
  padding: 6px 12px 6px 12px;
  text-align: left;
  font-size: 16px;

  &.AUD {
    background-image: url('/images/currencies/aud.svg');
  }
  &.EUR {
    background-image: url('/images/currencies/euro.svg');
  }
  &.GBP {
    background-image: url('/images/currencies/gbp.svg');
  }
  &.NZD {
    background-image: url('/images/currencies/nzd.svg');
  }
  &.USD {
    background-image: url('/images/currencies/usa.svg');
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const useCurrency = createPersistedState('currency');

const CountrySelector = () => {
  const [activeCurrency, setActiveCurrency] = useCurrency<string>();
  const [open, setOpen] = useState(false);

  const selectCurrency = (curr: string) => () => {
    setActiveCurrency(curr);
    setOpen(false);
  };

  const toggleOpen = () => setOpen(!open);

  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);

  useEffect(() => {
    if (activeCurrency !== undefined) {
      setCurrency(getCurrency(activeCurrency));
    }
  }, [activeCurrency]);

  const items = Currencies.supported.map(({ code }) => (
    <CurrencyOptionItem
      key={code}
      onClick={selectCurrency(code)}
      className={currency.code === code ? `${code} active` : code}
    >
      {code}
    </CurrencyOptionItem>
  ));

  return (
    <Container>
      <CurrentCurrency className={currency.code} onClick={() => toggleOpen()}>
        {currency.code}
        <CurrencyMenuOpener className={open ? 'open' : 'close'} />
      </CurrentCurrency>
      {open && (
        <CurrencyOptions>
          <CurrencyDivider />
          {items}
        </CurrencyOptions>
      )}
    </Container>
  );
};

const CurrencySelectorV2 = () => (
  <CurrenctContainer>
    <CountrySelector />
  </CurrenctContainer>
);

export { CountrySelector, CurrencySelectorV2 };
