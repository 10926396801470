import React, { FunctionComponent } from 'react';
import { Introduction } from './Introduction';
import { BlogHeader } from '@bunac/components/Programme/Blogs/Header';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Image } from '@bunac/components/Programme/Image';

interface HeroHeaderProps {
  title: string;
  subtitle?: string;
  text?: string;
  ctas?: string[];
  urls?: string[];
  image?: ImageDataLike;
  imageLink?: string;
  blogs?: boolean;
  locationPage?: boolean;
  includeCurrencySelector?: boolean;
  includeCountrySelector?: boolean;
  includeGetInTouch?: boolean;
}

const HeroHeader: FunctionComponent<HeroHeaderProps> = ({
  title,
  subtitle,
  text,
  ctas,
  urls,
  image,
  imageLink,
  locationPage = false,
  blogs = false,
  includeCurrencySelector = false,
  includeCountrySelector = false,
  includeGetInTouch = false,
}) => (
  <div className="relative min-h-full">
    <div className="relative">
      {imageLink ? (
        <a href={imageLink}>
          <Image
            image={image}
            alt={''}
            className="object-cover h-96 lg:h-auto"
          />
        </a>
      ) : (
        <Image image={image} alt={''} className="object-cover h-96 lg:h-auto" />
      )}
      <div className="torn" />
    </div>
    <div className="relative px-4 -top-40 -mb-36 lg:-top-56 lg:-mb-52">
      {!blogs && (
        <Introduction
          title={title}
          subtitle={subtitle}
          text={text}
          ctas={ctas}
          urls={urls}
          locationPage={locationPage}
          includeCurrencySelector={includeCurrencySelector}
          includeCountrySelector={includeCountrySelector}
          includeGetInTouch={includeGetInTouch}
        />
      )}
      {blogs && <BlogHeader title={title} subtitle={subtitle} />}
    </div>
  </div>
);

export { HeroHeader };
