import React, { FunctionComponent } from 'react';
import { CountrySelector, CurrencySelectorV2 } from './CountrySelector';
import { LinkButton } from './LinkButton';
import { MarkdownRender } from './MarkdownRender';

interface IntroductionProps {
  title: string;
  subtitle?: string;
  text?: string;
  ctas?: string[];
  urls?: string[];
  locationPage?: boolean;
  includeCurrencySelector?: boolean;
  includeCountrySelector?: boolean;
  includeGetInTouch?: boolean;
}

interface PageTitleProps {
  title: string;
  subtitle?: string;
  bold: boolean;
}

const PageTitle: FunctionComponent<PageTitleProps> = ({
  title,
  subtitle,
  bold,
}) => (
  <>
    {!bold && (
      <h1 className="font-worksans font-light flex-none text-4xl text-center text-white my-3">
        {title}
      </h1>
    )}
    {!bold && subtitle && (
      <h2 className="font-worksans text-center flex-none text-lg font-semibold uppercase text-navy">
        {subtitle}
      </h2>
    )}
    {bold && (
      <h1 className="font-fave flex-none text-5xl text-center text-navy my-3">
        {title}
      </h1>
    )}
    {bold && subtitle && (
      <h2 className="font-worksans font-light flex-none text-4xl text-center text-white">
        {subtitle}
      </h2>
    )}
  </>
);

const Introduction: FunctionComponent<IntroductionProps> = ({
  title,
  subtitle,
  text,
  ctas,
  urls,
  locationPage = false,
  includeCurrencySelector = false,
  includeCountrySelector = false,
  includeGetInTouch = false
}) => (
  <div className="relative px-6 pt-14 pb-8 lg:py-8 max-w-screen-lg mx-auto bg-blue rounded-3xl shadow-md flex flex-col items-center space-x-4">
    <PageTitle title={title} subtitle={subtitle} bold={locationPage === true} />
    {text && (
      <MarkdownRender
        content={text}
        className="editor-intro text-white text-base text-center md:max-w-2xl"
      />
    )}
    {urls && (
      <div className="flex flex-nowrap gap-6 mt-6">
        <LinkButton ctas={ctas} urls={urls} color={'navy'} />
        {includeGetInTouch && (
          <LinkButton ctas={["Get in touch"]} urls={["#contact-form"]} color={'white'} />
        )}
      </div>
    )}
    {includeCurrencySelector && <CurrencySelectorV2 />}
    {includeCountrySelector && (
      <>
        <CountrySelector />
      </>
    )}
  </div>
);

export { Introduction };
