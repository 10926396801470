import React, { useEffect } from 'react';

const CookieDeclaration = () => {
  useEffect(() => {
    const cookieBotWrapper = document.getElementById("CookiebotDeclaration")
    if (cookieBotWrapper) {
      const script = document.createElement("script")
      script.id = "CookieDeclaration"
      script.type = "text/javascript"
      script.async = true
      script.src = `https://consent.cookiebot.com/d1f91a05-2f0c-41d8-8c2c-1cc4b7f45ece/cd.js`
  
      cookieBotWrapper.appendChild(script)
  }
  }, [])

  return (
    <div id="CookiebotDeclaration" className='p-3'/>
  );
};

export { CookieDeclaration };
